import * as React from "react";
import { Box, Typography, Link, Button } from "@mui/material";
import Layout from "../components/Layout";
import { PageProps } from "gatsby";
import { SEO } from "../components/SEO";

import { OutboundLink } from "gatsby-plugin-google-gtag";
import Arrow from "../images/arrow-right.inline.svg";

import house from "../images/mainnet/house.svg";
import map from "../images/mainnet/map.svg";
import sideBrand2 from "../images/pitLine2.svg";
import bricks2 from "../images/career/bricks.svg";
import simpleLine from "../images/simpleLine.svg";
import Qr from "../images/mainnet/qr.inline.svg";

const anchor = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  position: "relative",
  height: 92,
  background: "#0C1F47",
  border: "1px solid #4B608D",
  letterSpacing: "0.35em",
  textTransform: "uppercase",
  transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
  fontSize: { sm: 15 },
  "&:before": {
    content: '""',
    position: "absolute",
    left: 10,
    top: -10,
    width: "100%",
    height: "100%",
    background: "#0C1F47",
    border: "1px solid #4B608D",
    zIndex: -1,
    transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
  },
  "&:hover": {
    boxShadow: "0 0 20px 0 rgba(255, 255, 255, .3)",
    background: "#4B608D",
    border: "1px solid #4B608D",
    "&:before": {
      left: "0",
      top: "0",
    },
  },
};

const dine = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: "center",
  position: "relative",
  height: 108,
  background: "#0C1F47",
  border: "1px solid #4B608D",
  transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
  p: 1,
  "&:before": {
    content: '""',
    position: "absolute",
    left: 10,
    top: -10,
    width: "100%",
    height: "100%",
    background: "#0C1F47",
    border: "1px solid #4B608D",
    zIndex: -1,
    transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
  },
  "&:hover": {
    boxShadow: "0 0 20px 0 rgba(255, 255, 255, .3)",
    background: "#4B608D",
    border: "1px solid #4B608D",
    "&:before": {
      left: "0",
      top: "0",
    },
  },
};

const pass = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: "center",
  position: "relative",
  height: 108,
  background: "#0C1F47",
  border: "1px solid #4B608D",
  transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
  p: 1,
  "&:before": {
    content: '""',
    position: "absolute",
    left: 10,
    top: -10,
    width: "100%",
    height: "100%",
    background: "#0C1F47",
    border: "1px solid #4B608D",
    zIndex: -1,
    transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
  },
};

const box = {
  background: "#0C1F47",
  border: "1px solid #4B608D",
  transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
  px: 4,
  py: 3,
  position: "relative",
  "&:before": {
    content: '""',
    position: "absolute",
    left: 10,
    top: -10,
    width: "100%",
    height: "100%",
    background: "#0C1F47",
    border: "1px solid #4B608D",
    zIndex: -1,
    transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
  },
  "&:hover": {
    boxShadow: "0 0 20px 0 rgba(255, 255, 255, .3)",
    background: "#4B608D",
    border: "1px solid #4B608D",
    "&:before": {
      left: "0",
      top: "0",
    },
  },
};

const restaurants = [
  {
    name: "Rubirosa",
    text: "Order the pizza",
    url: "https://www.doordash.com/store/19901",
  },
  {
    name: "L’Artusi",
    text: "Order the pizza",
    url: "https://www.doordash.com/store/987908",
  },
  {
    name: "Domodomo",
    text: "Order the sushi",
    url: "https://www.doordash.com/store/1027796",
  },
  {
    name: "Raku",
    text: "Order the udon",
    url: "https://www.doordash.com/store/844843",
  },
  {
    name: "Boqueria",
    text: "Order the tapas",
    url: "https://www.doordash.com/store/177538",
  },
  {
    name: "Shuka",
    text: "Order the mezes",
    url: "https://www.doordash.com/store/705702",
  },
  {
    name: "12 Chairs",
    text: "Order breakfast",
    url: "https://www.doordash.com/store/54630",
  },
  {
    name: "Westville",
    text: "Order the Marketplate",
    url: "https://www.doordash.com/store/531594?utm_source=mx_share",
  },
  {
    name: "Blue Ribbon",
    text: "Order the fried chicken",
    url: "https://www.doordash.com/store/877535?utm_source=mx_share",
  },
  {
    name: "Joe’s Shanghai",
    text: "Order the soup dumplings",
    url: "https://www.doordash.com/store/886294?utm_source=mx_share",
  },
  {
    name: "Levain Bakery",
    text: "Order the cookies",
    url: "https://www.doordash.com/store/1070461?utm_source=mx_share",
  },
  {
    name: "Veselka",
    text: "Order perogis",
    url: "https://www.doordash.com/store/999047?utm_source=mx_share",
  },
  {
    name: "Joe’s Pizza",
    text: "Order a slice",
    url: "https://www.doordash.com/store/29417?utm_source=mx_share",
  },
  {
    name: "Taqueria Diana",
    text: "Order the tacos",
    url: "https://www.doordash.com/store/taqueria-diana-new-york-64317/",
  },
  {
    name: "Cafe Mogador",
    text: "Order brunch",
    url: "https://www.doordash.com/store/9199?utm_source=mx_share",
  },
];

const diners = [
  {
    name: "Catch",
    time: "September 21st at 7 PM for 10 guests ",
    direction: "https://goo.gl/maps/UwMeDH4N6bAAjkmx5",
  },
  {
    name: "Viva Cucina",
    time: "September 22nd at 7 PM for 10 guests ",
    direction: "https://goo.gl/maps/rRmDcpQAc3KLHrPGA",
  },
  {
    name: "Mercer Kitchen",
    time: "September 23rd at 7 PM for 8 guests ",
    direction: "https://goo.gl/maps/cNKazV9SRr25pzGm6",
  },
];

const gyms = [
  {
    name: "Equinox",
    time: "(membership only)",
    address: "0 Bond St",
    direction: "https://goo.gl/maps/NR1aJShmph2zreX18",
  },
  {
    name: "SoulCycle",
    time: "(membership only)",
    address: "384 Lafayette St",
    direction: "https://goo.gl/maps/31qU6guZviVe3Qiq9",
  },
  {
    name: "Barry’s",
    time: "(membership only)",
    address: "419 Lafayette St",
    direction: "https://goo.gl/maps/xxhumKXm5VgxDRe37",
  },
  {
    name: "Blink Fitness",
    time: "(offers free trials)",
    address: "16 E 4th St",
    direction: "https://goo.gl/maps/VEiJH8xBxWoNQ2wo6",
  },
  {
    name: "Crunch Fitness",
    time: "(offers free trials)",
    address: "2 Cooper Sq",
    direction: "https://g.page/CrunchBowery?share",
  },
];

const mainnet = ({ location }: PageProps) => {
  return (
    <Layout>
      <SEO title="Mainnet" pathname={location.pathname}>
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
      </SEO>
      <Box
        component="span"
        sx={{
          position: "absolute",
          left: "65px",
          top: "200px",
          height: "calc(100% - 415px)",
          width: 0,
          borderLeft: "1px solid #4B608D",
          display: { xs: "none", xl: "block" },
          zIndex: -1,
          "&:before": {
            content: '""',
            position: "absolute",
            top: "-5px",
            left: "-8px",
            width: "15px",
            height: "5px",
            backgroundColor: "#1CE7C2",
          },
        }}
      />
      <Box
        component="div"
        sx={{
          textAlign: "center",
          pt: { xs: 8, md: 14 },
          maxWidth: 680,
          mx: "auto",
        }}
      >
        <Box
          component="img"
          src={house}
          alt="Jump House"
          sx={{ display: "block", mx: "auto", mb: 3 }}
        />
        <Typography variant="h1">
          Welcomes you to
          <br /> Mainnet 2022
        </Typography>
      </Box>
      <Box
        component="img"
        src={map}
        sx={{
          width: "100%",
          mt: { md: "-100px", lg: "-200px" },
          transform: "scale(1.05)",
          zIndex: -2,
        }}
      />

      <Box
        component="div"
        sx={{
          display: { xs: "block", md: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          maxWidth: 1080,
          mx: "auto",
          mt: { md: "-5vw" },
        }}
      >
        <Box
          component="div"
          sx={{
            textAlign: { xs: "center", md: "initial" },
            width: { md: "45%" },
            mb: { xs: 5, md: 0 },
          }}
        >
          <Typography variant="h2" sx={{ mb: 1.6 }}>
            There’s a lot to see and{" "}
            <Box component="span" sx={{ color: "#1CE7C2" }}>
              Do This Week.
            </Box>
          </Typography>
          <Typography sx={{ fontSize: 16 }}>
            So, in order to make sure you don’t waste your time, we’ve gone
            ahead and made some food and travel arrangements for you.
          </Typography>
        </Box>
        <Box
          component="div"
          sx={{
            width: { md: "50%" },
            mx: { xs: "auto", md: "initial" },
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "24px",
          }}
        >
          <Link sx={anchor} href="#wifi">
            WIFI
          </Link>
          <Link sx={anchor} href="#dineout">
            DINE OUT
          </Link>
          <Link sx={anchor} href="#concierge">
            CONCIERGE
          </Link>
          <Link sx={anchor} href="#orderin">
            ORDER IN
          </Link>
          <Link sx={anchor} href="#travel">
            Travel
          </Link>
          <Link sx={anchor} href="#gym">
            GYM
          </Link>
        </Box>
      </Box>

      <Box component="div" sx={{ maxWidth: 1080, mx: "auto", pt: 3 }} id="wifi">
        <Box
          component="div"
          sx={{
            maxWidth: 590,
            textAlign: { xs: "center", md: "initial" },
            mx: { xs: "auto", md: "0" },
            mt: { xs: 10, md: 16 },
            mb: 3.2,
          }}
        >
          <Typography variant="h2" sx={{ mb: 1 }}>
            <Box component="span" sx={{ color: "#1CE7C2" }}>
              Wi-Fi?
            </Box>{" "}
            Obv
          </Typography>
        </Box>
      </Box>

      <Box component="div" sx={{ position: "relative" }}>
        <Box
          component="span"
          sx={{
            position: "relative",
            "&:before": {
              content: `url(${simpleLine})`,
              position: "absolute",
              left: 40,
              top: 50,
              display: { xs: "none", xl: "block" },
              zIndex: -1,
            },
          }}
        />
        <Box component="div" sx={{ maxWidth: 1080, mx: "auto", pt: 3 }}>
          <Box
            component="div"
            sx={{
              position: "relative",
              display: "grid",
              gridTemplateColumns: { sm: "repeat(2, 1fr)" },
              gap: "24px",
              maxWidth: 600,
            }}
          >
            <Box
              component="span"
              sx={{
                position: "absolute",
                left: 0,
                top: 30,
                width: "100%",
                height: 0,
                borderBottom: "1px solid #4B608D",
                zIndex: -2,
                display: { xs: "none", xl: "block" },
              }}
            />
            <Box
              component="div"
              sx={{
                ...pass,
                alignItems: "flex-start",
                px: 3,
              }}
            >
              <Typography variant="h5" sx={{ mb: 1 }}>
                SpectrumSetup-BD
              </Typography>
              <Typography sx={{ color: "#fff", letterSpacing: "-0.01em" }}>
                Network
              </Typography>
            </Box>
            <Box
              component="div"
              sx={{ ...pass, alignItems: "flex-start", px: 3 }}
            >
              <Typography variant="h5" sx={{ mb: 1 }}>
                safetybotany208
              </Typography>
              <Typography sx={{ color: "#fff", letterSpacing: "-0.01em" }}>
                Password
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        component="div"
        sx={{
          position: "relative",
          pt: { md: 10 },
          "&:before": {
            content: '""',
            position: "absolute",
            width: "calc(50% - 220px)",
            left: 50,
            top: 200,
            height: 0,
            borderTop: "1px solid #4B608D",
            display: { xs: "none", xl: "block" },
          },
          "&:after": {
            content: `url(${sideBrand2})`,
            position: "absolute",
            left: "calc(50% - 400px)",
            top: 198,
            display: { xs: "none", xl: "block" },
            zIndex: -1,
          },
        }}
      >
        <Box
          component="img"
          src={bricks2}
          sx={{
            position: "absolute",
            right: "50px",
            top: "0",
            zIndex: -1,
            pointerEvents: "none",
          }}
        />

        <Box
          component="div"
          sx={{
            maxWidth: 710,
            mx: "auto",
            textAlign: "center",
            mt: { xs: 10, md: 21 },
            pt: 3,
          }}
          id="concierge"
        >
          <Typography variant="h2" sx={{ color: "#1CE7C2", mb: 1.6 }}>
            Concierge
          </Typography>
          <Typography sx={{ mb: 4, fontSize: 16 }}>
            The Concierge is around throughout Mainnet (note that the Concierge
            would *like* to be around 24/7 but will probably need to shut it
            down at some point) to help give you recommendations on places to
            eat, things to do, nearby bodegas and pharmacies where you can buy
            anything you may have forgotten, and many other things. Hit up the
            Concierge here:
          </Typography>
          <Qr />
        </Box>
      </Box>

      <Box
        component="div"
        sx={{
          position: "relative",
          pt: { md: 10 },
          "&:before": {
            content: '""',
            position: "absolute",
            width: "calc(50% - 220px)",
            left: 50,
            top: 200,
            height: 0,
            borderTop: "1px solid #4B608D",
            display: { xs: "none", xl: "block" },
          },
          "&:after": {
            content: `url(${sideBrand2})`,
            position: "absolute",
            left: "calc(50% - 400px)",
            top: 198,
            display: { xs: "none", xl: "block" },
            zIndex: -1,
          },
        }}
      >
        <Box
          component="div"
          sx={{
            maxWidth: 500,
            mx: "auto",
            textAlign: "center",
            my: { xs: 10, md: 20 },
            pt: 3,
          }}
          id="travel"
        >
          <Typography variant="h2" sx={{ color: "#1CE7C2", mb: 1.6 }}>
            Travel
          </Typography>
          <Typography sx={{ mb: 3, fontSize: 16 }}>
            You’re welcome to take the subway for a real taste (and scent) of
            the city.
          </Typography>
          <Typography sx={{ mb: 4, fontSize: 16 }}>
            But if you’d rather an Uber, grab a code for complimentary rides to
            or from Jump House.
          </Typography>
          <Button
            variant="outlined"
            component={OutboundLink}
            href="https://r.uber.com/rlwwbkvFxoA"
            target="_blank"
            endIcon={<Arrow />}
          >
            UBER CODE
          </Button>
        </Box>
      </Box>

      <Box
        component="div"
        sx={{ maxWidth: 1080, mx: "auto", pt: 3 }}
        id="dineout"
      >
        <Box
          component="div"
          sx={{
            maxWidth: 590,
            textAlign: { xs: "center", md: "initial" },
            mx: { xs: "auto", md: "0" },
          }}
        >
          <Typography variant="h2" sx={{ color: "#1CE7C2", mb: 1.6 }}>
            Dine out
          </Typography>
          <Typography sx={{ mb: 4, fontSize: 16, letterSpacing: "-0.01em" }}>
            Reservations for big crews can be a serious pain in the a... anyway,
            we’ve held a few great spots just in case you need it. Reservations
            are under "A. Strashnov"
          </Typography>
        </Box>
      </Box>
      <Box component="div" sx={{ position: "relative" }}>
        <Box
          component="span"
          sx={{
            position: "relative",
            "&:before": {
              content: `url(${simpleLine})`,
              position: "absolute",
              left: 40,
              top: 20,
              display: { xs: "none", xl: "block" },
              zIndex: -1,
            },
          }}
        />
        <Box
          component="div"
          sx={{
            position: "relative",
            maxWidth: 1080,
            mx: "auto",
            display: "grid",
            gridTemplateColumns: { xs: "repeat(1, 1fr)", md: "repeat(3, 1fr)" },
            gap: { xs: 2.4, md: 6 },
            mt: 10,
          }}
        >
          <Box
            component="span"
            sx={{
              position: "absolute",
              left: 0,
              top: 30,
              width: "100%",
              height: 0,
              borderBottom: "1px solid #4B608D",
              zIndex: -2,
              display: { xs: "none", xl: "block" },
            }}
          />
          {diners.map((d) => (
            <Link
              component={OutboundLink}
              sx={box}
              href={d.direction}
              target="_blank"
              rel="noreferrer"
            >
              <Typography variant="h5">{d.name}</Typography>
              <Typography sx={{ maxWidth: 190, mb: 2, mt: 1 }}>
                {d.time}
              </Typography>
              <Box
                component="span"
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  color: "#1CE7C2",
                  "& svg": { fill: "currentcolor", ml: 1 },
                }}
              >
                Directions <Arrow />
              </Box>
            </Link>
          ))}
        </Box>
      </Box>

      <Box
        component="div"
        sx={{
          maxWidth: 680,
          mx: "auto",
          textAlign: "center",
          mt: { xs: 10, md: 20 },
          pt: 3,
          mb: 5,
        }}
        id="orderin"
      >
        <Typography variant="h2" sx={{ color: "#1CE7C2", mb: 1.6 }}>
          Order-in
          <Box component="span" sx={{ color: "#fff" }}>
            {" and "}
          </Box>
          Catering
        </Typography>
        <Typography sx={{ mb: 4, fontSize: 16 }}>
          Lunch will be brought in every day, and there are always great options
          in the fridge. But if you want to order-in, here are a few great dish
          recos at a few restaurants we love in and around the neighborhood.
        </Typography>
      </Box>

      <Box
        component="div"
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "repeat(2, 1fr)", md: "repeat(3, 1fr)" },
          gap: "20px",
          maxWidth: 850,
          mx: "auto",
        }}
      >
        {restaurants.map((r) => (
          <Link sx={dine} target="_blank" rel="noreferrer" href={r.url}>
            <Typography variant="h5" sx={{ mb: 1 }}>
              {r.name}
            </Typography>
            <Typography sx={{ color: "#1CE7C2", letterSpacing: "-0.01em" }}>
              {r.text}
            </Typography>
          </Link>
        ))}
      </Box>

      <Box component="div" sx={{ position: "relative" }}>
        <Box
          component="span"
          sx={{
            position: "relative",
            "&:before": {
              content: `url(${simpleLine})`,
              position: "absolute",
              left: 40,
              top: 80,
              display: { xs: "none", xl: "block" },
              zIndex: -1,
            },
          }}
        />
        <Box
          component="div"
          sx={{
            maxWidth: 1080,
            mx: "auto",
            display: "grid",
            gridTemplateColumns: { xs: "repeat(1, 1fr)", md: "repeat(2, 1fr)" },
            gap: "12px",
            mt: { xs: 10, md: 22 },
          }}
        >
          <Box
            component="div"
            sx={{
              background: "#0C1F47",
              border: "1px solid #4B608D",
              textAlign: "center",
              px: { xs: 4, md: 10 },
              py: 7,
              "& h4": {
                mb: 5,
              },
            }}
          >
            <Typography variant="h4">
              Want something{" "}
              <Box component="span" sx={{ color: "#1CE7C2" }}>
                fancier delivered?
              </Box>
            </Typography>
            <Button
              variant="outlined"
              component={OutboundLink}
              href="https://www.trycaviar.com/"
              target="_blank"
              endIcon={<Arrow />}
            >
              Try Caviar app
            </Button>
          </Box>

          <Box
            component="div"
            sx={{
              background: "#0C1F47",
              border: "1px solid #4B608D",
              textAlign: "center",
              px: { xs: 4, md: 10 },
              py: 7,
              "& h4": {
                mb: 5,
              },
            }}
          >
            <Typography variant="h4">
              Want something{" "}
              <Box component="span" sx={{ color: "#1CE7C2" }}>
                specific?
              </Box>
            </Typography>
            <Button
              variant="outlined"
              component={OutboundLink}
              href="#concierge"
              endIcon={<Arrow />}
            >
              Ping the Concierge
            </Button>
          </Box>
        </Box>
      </Box>

      <Box
        component="div"
        sx={{
          maxWidth: 1080,
          mx: "auto",
          textAlign: "center",
          mt: { xs: 10, md: 20 },
          pt: 3,
        }}
        id="bar"
      >
        <Box component="div">
          <Typography variant="h2" sx={{ color: "#1CE7C2", mb: 1.6 }}>
            Bartender
          </Typography>
          <Typography sx={{ mb: 6, fontSize: 16 }}>
            Enjoy one of our signature craft cocktails this Friday from 7pm to
            midnight
          </Typography>
        </Box>
      </Box>

      <Box component="div" sx={{ position: "relative" }}>
        <Box
          component="span"
          sx={{
            position: "relative",
            "&:before": {
              content: `url(${simpleLine})`,
              position: "absolute",
              left: 40,
              top: 20,
              display: { xs: "none", xl: "block" },
              zIndex: -1,
            },
          }}
        />
        <Box
          component="div"
          sx={{ maxWidth: 1080, mx: "auto", textAlign: "center" }}
          id="concierge"
        >
          <Box
            component="div"
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "center", md: "flex-start" },
              justifyContent: "space-between",
              gap: "24px",
            }}
          >
            <Box
              component="span"
              sx={{
                position: "absolute",
                left: 0,
                top: 30,
                width: "100%",
                height: 0,
                borderBottom: "1px solid #4B608D",
                zIndex: -2,
                display: { xs: "none", xl: "block" },
              }}
            />
            <Box
              component="div"
              sx={{ width: { md: "33.33%" }, maxWidth: 324 }}
            >
              <Box
                component="div"
                sx={{
                  p: 1.6,
                  background: "#0C1F47",
                  border: "1px solid #4B608D",
                  mb: 2.4,
                }}
              >
                <Typography variant="h4">
                  the jumparound
                  <Box component="span" sx={{ color: "#1CE7C2" }}>
                    _
                  </Box>
                </Typography>
              </Box>
              <Typography sx={{ maxWidth: 220, mx: "auto" }}>
                (triple-filtered vodka, Red Bull){" "}
              </Typography>
            </Box>
            <Box
              component="div"
              sx={{ width: { md: "33.33%" }, maxWidth: 324 }}
            >
              <Box
                component="div"
                sx={{
                  p: 1.6,
                  background: "#0C1F47",
                  border: "1px solid #4B608D",
                  mb: 2.4,
                }}
              >
                <Typography variant="h4">
                  the billder
                  <Box component="span" sx={{ color: "#1CE7C2" }}>
                    _
                  </Box>
                </Typography>
              </Box>
              <Typography sx={{ maxWidth: 260, mx: "auto" }}>
                (1/3 Casamigo blanco infused with plenty of jalapeno’s, 2/3
                zinzang all-natural Margarita mix)
              </Typography>
            </Box>
            <Box
              component="div"
              sx={{ width: { md: "33.33%" }, maxWidth: 324 }}
            >
              <Box
                component="div"
                sx={{
                  p: 1.6,
                  background: "#0C1F47",
                  border: "1px solid #4B608D",
                  mb: 2.4,
                }}
              >
                <Typography variant="h4">
                  the pythia
                  <Box component="span" sx={{ color: "#1CE7C2" }}>
                    _
                  </Box>
                </Typography>
              </Box>
              <Typography sx={{ maxWidth: 260, mx: "auto" }}>
                (2 oz. Ouzo, 1.5 oz. lemon juice, 3 mint leaves, 1tsp honey, 3
                oz. water)
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        component="div"
        sx={{
          position: "relative",
          pt: { md: 10 },
          "&:before": {
            content: '""',
            position: "absolute",
            width: "calc(50% - 220px)",
            left: 50,
            top: 200,
            height: 0,
            borderTop: "1px solid #4B608D",
            display: { xs: "none", xl: "block" },
          },
          "&:after": {
            content: `url(${sideBrand2})`,
            position: "absolute",
            left: "calc(50% - 400px)",
            top: 198,
            display: { xs: "none", xl: "block" },
            zIndex: -1,
          },
        }}
      >
        <Box component="div" id="gym" sx={{ mb: { xs: 10, md: 20 } }}>
          <Box
            component="div"
            sx={{
              maxWidth: 1080,
              mx: "auto",
              textAlign: "center",
              mt: { xs: 10, md: 20 },
              pt: 3,
            }}
            id="concierge"
          >
            <Box component="div" sx={{}}>
              <Typography variant="h2" sx={{ mb: 1.6 }}>
                It’s a Marathon,<br />
                <Box component="span" sx={{ color: "#1CE7C2" }}>
                  not a sprint.
                </Box>
              </Typography>
              <Typography
                sx={{ mb: 4, fontSize: 16, maxWidth: 320, mx: "auto" }}
              >
                Stay strong, by visiting one of the many nearby gyms in the
                area.
              </Typography>
            </Box>
          </Box>

          <Box
            component="div"
            sx={{
              position: "relative",
              maxWidth: 1080,
              mx: "auto",
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                md: "repeat(3, 1fr)",
              },
              gap: 2,
              mt: 10,
            }}
          >
            {gyms.map((d) => (
              <Link
                component={OutboundLink}
                sx={box}
                href={d.direction}
                target="_blank"
                rel="noreferrer"
              >
                <Typography variant="h5">{d.name}</Typography>
                <Typography sx={{ maxWidth: 190, mb: 2, mt: 1 }}>
                  {d.time}
                </Typography>
                <Box
                  component="span"
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    color: "#1CE7C2",
                    "& svg": { fill: "currentcolor", ml: 1 },
                  }}
                >
                  {d.address} <Arrow />
                </Box>
              </Link>
            ))}
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default mainnet;
